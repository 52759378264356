import { createSlice } from "@reduxjs/toolkit";

export const StaffPayEntrySlice = createSlice({
  name: "staffPayEntry",
  initialState: {
    staffPayEntries: [],
  },
  reducers: {
    fetchStaffPayEntries: (state, action) => {
      state.staffPayEntries = action.payload;
    },
    addStaffPayEntry: (state, action) => {
      state.staffPayEntries = [action.payload, ...state.staffPayEntries];
    },
    updateStaffPayEntry: (state, action) => {
      let index = state.staffPayEntries.findIndex(
        (staffPayEntry) =>
          parseInt(staffPayEntry.linker) === parseInt(action.payload.linker)
      );

      let prevStaffPayEntries = [...state.staffPayEntries];

      prevStaffPayEntries.splice(
        index < 0 ? 1 : index,
        index < 0 ? 0 : 1,
        action.payload
      );

      state.staffPayEntries = prevStaffPayEntries;
    },
    deleteStaffPayEntry: (state, action) => {
      let newStaffPayEntries = state.staffPayEntries.filter(
        (staffPayEntry) =>
          parseInt(staffPayEntry.linker) !== parseInt(action.payload.linker)
      );
      state.staffPayEntries = newStaffPayEntries;
    },
  },
});

export const {
  fetchStaffPayEntries,
  addStaffPayEntry,
  updateStaffPayEntry,
  deleteStaffPayEntry,
} = StaffPayEntrySlice.actions;

export default StaffPayEntrySlice.reducer;
