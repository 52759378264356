import { createSlice } from "@reduxjs/toolkit";

export const PayrollItemSlice = createSlice({
  name: "payrollItem",
  initialState: {
    payrollItems: [],
  },
  reducers: {
    fetchPayrollItems: (state, action) => {
      state.payrollItems = action.payload;
    },
    addPayrollItem: (state, action) => {
      state.payrollItems = [action.payload, ...state.payrollItems];
    },
    updatePayrollItem: (state, action) => {
      let index = state.payrollItems.findIndex(
        (payrollItem) =>
          parseInt(payrollItem.linker) === parseInt(action.payload.linker)
      );

      let prevPayrollItems = [...state.payrollItems];

      prevPayrollItems.splice(
        index < 0 ? 1 : index,
        index < 0 ? 0 : 1,
        action.payload
      );

      state.payrollItems = prevPayrollItems;
    },
    deletePayrollItem: (state, action) => {
      let newPayrollItems = state.payrollItems.filter(
        (payrollItem) =>
          parseInt(payrollItem.linker) !== parseInt(action.payload.linker)
      );
      state.payrollItems = newPayrollItems;
    },
  },
});

export const {
  fetchPayrollItems,
  addPayrollItem,
  updatePayrollItem,
  deletePayrollItem,
} = PayrollItemSlice.actions;

export default PayrollItemSlice.reducer;
