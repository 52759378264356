import React from "react";

const NoEntry = () => {
  return (
    <tbody>
      <tr>
        <th>No Entries</th>

        <td>Entries are various products transactions</td>
      </tr>
    </tbody>
  );
};

export default NoEntry;
