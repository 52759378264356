import { createSlice } from "@reduxjs/toolkit";

export const StaffPayItemSlice = createSlice({
  name: "staffPayItem",
  initialState: {
    staffPayItems: [],
  },
  reducers: {
    fetchStaffPayItems: (state, action) => {
      state.staffPayItems = action.payload;
    },
    addStaffPayItem: (state, action) => {
      state.staffPayItems = [action.payload, ...state.staffPayItems];
    },
    updateStaffPayItem: (state, action) => {
      let index = state.staffPayItems.findIndex(
        (staffPayItem) =>
          parseInt(staffPayItem.linker) === parseInt(action.payload.linker)
      );

      let prevStaffPayItems = [...state.staffPayItems];

      prevStaffPayItems.splice(
        index < 0 ? 1 : index,
        index < 0 ? 0 : 1,
        action.payload
      );

      state.staffPayItems = prevStaffPayItems;
    },
    deleteStaffPayItem: (state, action) => {
      let newStaffPayItems = state.staffPayItems.filter(
        (staffPayItem) =>
          parseInt(staffPayItem.linker) !== parseInt(action.payload.linker)
      );
      state.staffPayItems = newStaffPayItems;
    },
  },
});

export const {
  fetchStaffPayItems,
  addStaffPayItem,
  updateStaffPayItem,
  deleteStaffPayItem,
} = StaffPayItemSlice.actions;

export default StaffPayItemSlice.reducer;
