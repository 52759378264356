import swal from "sweetalert";
import { fetchOnlinePays, updateOnlinePay } from "../reducers/OnlinePaySlice";
import { ApiUrl } from "./Urls";

export const FetchOnlinePays = async (
	token,
	instLinker,
	dispatch,
	online,
	from = "fetch",
	payload = {}
) => {
	if (from === "fetch") {
		FetchLiveOnlinePays(token, instLinker, online, dispatch);
	} else if (parseInt(instLinker) === parseInt(payload.instLinker)) {
		dispatch(updateOnlinePay(payload));
	}
};

export const updateLiveOnlinePay = (onlinePay, token, dispatch) => {
	return fetch(`${ApiUrl}/online/register/${onlinePay.type}`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...onlinePay,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				swal({
					title: `Connection Successfull`,
					text: "You can go ahead and test by paying to your shortcode and check the mpesa transactions to see the transaction",
					icon: "success",
				});
				if (onlinePay.type === "live-edit" || onlinePay.type === "live-add") {
					dispatch(updateOnlinePay(res.onlinePay));
				}
			} else {
				swal({
					title: `${
						onlinePay.type === "live-edit" || onlinePay.type === "live-add"
							? "Connection Failed"
							: "Testing Failed"
					}`,
					text: "Confirm all details again",
					icon: "warning",
				});
			}
		})
		.catch(() => {
			swal({
				title: `${
					onlinePay.type === "live-edit" || onlinePay.type === "live-add"
						? "Connection Failed"
						: "Testing Failed"
				}`,
				text: "Confirm all details again",
				icon: "warning",
			});
		});
};

export const FetchLiveOnlinePays = (token, instLinker, online, dispatch) => {
	fetch(`${ApiUrl}/online/get`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			online,
			instLinker,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(fetchOnlinePays(res.onlinePays));
			}
		})
		.catch(() => {});
};
