import { createSlice } from "@reduxjs/toolkit";

export const PaymentSlice = createSlice({
  name: "payment",
  initialState: {
    payments: [],
  },
  reducers: {
    fetchPayments: (state, action) => {
      state.payments = action.payload;
    },
    addPayment: (state, action) => {
      state.payments = [action.payload, ...state.payments];
    },
    updatePayment: (state, action) => {
      let index = state.payments.findIndex(
        (payment) =>
          parseInt(payment.linker) === parseInt(action.payload.linker)
      );

      let prevPayments = [...state.payments];

      prevPayments.splice(
        index < 0 ? 1 : index,
        index < 0 ? 0 : 1,
        action.payload
      );

      state.payments = prevPayments;
    },
    deletePayment: (state, action) => {
      let newPayments = state.payments.filter(
        (payment) =>
          parseInt(payment.linker) !== parseInt(action.payload.linker)
      );
      state.payments = newPayments;
    },
  },
});

export const { fetchPayments, addPayment, updatePayment, deletePayment } =
  PaymentSlice.actions;

export default PaymentSlice.reducer;
