import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  addLivePayrollItem,
  updateLivePayrollItem,
} from "../../../../Network/PayrollItemApi";
import { useNavigate } from "react-router-dom";

const PayrollItemCrud = (props) => {
  //redux dispatch
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const ActiveCred = useSelector((state) => state.cred.activeCred);
  const User = useSelector((state) => state.auth.user);
  const [Loading, setLoading] = useState(false);
  const inst = useSelector((state) => state.inst.activeInst);
  //add cat
  const AddItem = async () => {
    let linker = Date.now();

    await addLivePayrollItem(
      {
        staffLinker: props.staffLinker,
        catLinker: props.item.catLinker,
        details: props.item.details,
        amount: props.item.amount,
        linker,
        instLinker: ActiveCred.linker,
        credLinker: ActiveCred.linker,
        live: 1,
        status: 0,
        trace: linker,
        deleted: 0,
      },
      ActiveCred.instLinker,
      User.token,
      dispatch
    );

    props.setItem({
      ...props.item,
      details: "",
      amount: "",
      catLinker: "",
    });
  };

  //edit item
  const EditItem = async () => {
    let { credLinker, trace, deleted } = props.item;

    trace = Date.now();
    credLinker = ActiveCred.linker;
    deleted = props.type === "delete" ? 1 : deleted;

    await updateLivePayrollItem(
      { ...props.item, trace, credLinker, deleted },
      User.token,
      dispatch
    );
  };

  const HandleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (props.type === "add") {
      await AddItem();
    } else {
      await EditItem();
    }
    setLoading(false);
    props.setShowModal(false);
  };

  return (
    <Modal
      show={props.ShowModal}
      onHide={() => props.setShowModal(false)}
      backdrop="static"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <p className="text-capitalize text-center">
            {props.type} Payroll Item For <br />
            {props.staffNo} {props.staffName}
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.cats.length > 0 ? (
          <Form onSubmit={HandleSubmit}>
            <div className="form-group">
              <label className="mb-2 text-capitalize">Payroll Item: </label>
              <select
                className="rounded-pill form-control"
                value={props.item.catLinker}
                onChange={(e) =>
                  props.setItem({
                    ...props.item,
                    catLinker: e.target.value,
                  })
                }
                required
                readOnly={props.type === "delete" ? true : false}
              >
                <option value={""}>Select Item</option>
                {props.cats.map((cat) => (
                  <option key={cat.linker} value={cat.linker}>
                    {cat.name}
                  </option>
                ))}
              </select>
              <hr />
            </div>
            <div className="form-group">
              <label className="mb-2 text-capitalize">Amount:</label>
              <input
                className="rounded-pill form-control"
                value={props.item.amount}
                placeholder={`enter amount`}
                onChange={(e) =>
                  props.setItem({
                    ...props.item,
                    amount: e.target.value,
                  })
                }
                type={"number"}
                required
                readOnly={props.type === "delete" ? true : false}
              />

              <hr />
            </div>
            <div className="form-group">
              <label className="mb-2 text-capitalize">Details:</label>
              <textarea
                className="rounded-pill form-control"
                value={props.item.details}
                placeholder={`enter details`}
                onChange={(e) =>
                  props.setItem({
                    ...props.item,
                    details: e.target.value,
                  })
                }
                type={"number"}
                required
                readOnly={props.type === "delete" ? true : false}
              />

              <hr />
            </div>
            <div className="d-flex justify-content-around mb-2">
              {Loading ? (
                <span className="spinner-border text-primary"></span>
              ) : props.type === "delete" ? (
                <Button variant="danger" type="submit">
                  Delete
                </Button>
              ) : (
                <Button variant="primary" type="submit">
                  Save
                </Button>
              )}
              <Button
                variant="secondary"
                type="button"
                onClick={() => props.setShowModal(false)}
              >
                Cancel
              </Button>
            </div>
          </Form>
        ) : (
          <div className="d-flex justify-content-center align-content-center jumbotron">
            <p className="h5 text-center">
              You do not have any payroll Items to add visit{" "}
              <span
                className="btn-link text-info"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  window.scroll(0, 0);
                  props.setShowModal(false);
                  navigate(`/${inst.name}/payroll-items`);
                }}
              >
                Add Item Categories
              </span>{" "}
              add then come back.
            </p>{" "}
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default PayrollItemCrud;
