import React from "react";

const NoExpense = () => {
  return (
    <tbody>
      <tr>
        <th>No Expenses</th>

        <td>
          Expenses here can be used to denote cashoutflows from the institution,
          you can use expense categories to easily manage
        </td>
      </tr>
    </tbody>
  );
};

export default NoExpense;
