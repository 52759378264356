import { createSlice } from "@reduxjs/toolkit";

export const CredSlice = createSlice({
  name: "cred",
  initialState: {
    creds: [],
    activeCred: {},
  },
  reducers: {
    fetchCreds: (state, action) => {
      state.creds = action.payload;
    },
    addCred: (state, action) => {
      state.creds = [action.payload, ...state.creds];
    },
    updateCred: (state, action) => {
      let index = state.creds.findIndex(
        (cred) => cred.linker == action.payload.linker
      );

      let prevCreds = [...state.creds];

      prevCreds.splice(
        index < 0 ? 1 : index,
        index < 0 ? 0 : 1,
        action.payload
      );

      state.creds = prevCreds;
    },
    deleteCred: (state, action) => {
      let newCreds = state.creds.filter(
        (cred) => cred.linker != action.payload.linker
      );
      state.creds = newCreds;
    },
    setActiveCred: (state, action) => {
      state.activeCred = action.payload;
    },
  },
});

export const { fetchCreds, addCred, updateCred, deleteCred, setActiveCred } =
  CredSlice.actions;

export default CredSlice.reducer;
