import { createSlice } from "@reduxjs/toolkit";

export const ExpenseCatSlice = createSlice({
  name: "expenseCat",
  initialState: {
    expenseCats: [],
  },
  reducers: {
    fetchExpenseCats: (state, action) => {
      state.expenseCats = action.payload;
    },
    addExpenseCat: (state, action) => {
      state.expenseCats = [action.payload, ...state.expenseCats];
    },
    updateExpenseCat: (state, action) => {
      let index = state.expenseCats.findIndex(
        (expenseCat) =>
          parseInt(expenseCat.linker) === parseInt(action.payload.linker)
      );

      let prevExpenseCats = [...state.expenseCats];

      prevExpenseCats.splice(
        index < 0 ? 1 : index,
        index < 0 ? 0 : 1,
        action.payload
      );

      state.expenseCats = prevExpenseCats;
    },
    deleteExpenseCat: (state, action) => {
      let newExpenseCats = state.expenseCats.filter(
        (expenseCat) =>
          parseInt(expenseCat.linker) !== parseInt(action.payload.linker)
      );
      state.expenseCats = newExpenseCats;
    },
  },
});

export const {
  fetchExpenseCats,
  addExpenseCat,
  updateExpenseCat,
  deleteExpenseCat,
} = ExpenseCatSlice.actions;

export default ExpenseCatSlice.reducer;
