import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { updateLivePayrollEntry } from "../../../../Network/PayrollEntryApi";

const PaySlipEntryCrud = (props) => {
  //redux dispatch
  const dispatch = useDispatch();

  const ActiveCred = useSelector((state) => state.cred.activeCred);
  const User = useSelector((state) => state.auth.user);
  const Cats = useSelector((state) => state.payrollCat.payrollCats);

  const GetCat = (catLinker) => {
    let cat = Cats.find((cat) => parseInt(catLinker) === parseInt(cat.linker));

    return cat || { name: catLinker };
  };

  const [Loading, setLoading] = useState(false);

  //edit item
  const EditItem = async () => {
    let {
      credLinker,

      trace,

      deleted,
    } = props.entry;

    trace = Date.now();
    credLinker = ActiveCred.linker;
    deleted = props.type === "delete" ? 1 : deleted;

    await updateLivePayrollEntry(
      { ...props.entry, trace, credLinker, deleted },
      User.token,
      dispatch
    );
  };

  const HandleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    await EditItem();

    setLoading(false);
    props.setShowModal(false);
  };

  return (
    <Modal
      show={props.ShowModal}
      onHide={() => props.setShowModal(false)}
      backdrop="static"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <p className="text-capitalize text-center">
            {props.type} Payslip Item For {props.entry.month}
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={HandleSubmit}>
          <div className="form-group">
            <label className="mb-2 text-capitalize">
              {GetCat(props.entry.catLinker).name}:
            </label>
            <input
              className="rounded-pill form-control"
              value={props.entry.amount}
              placeholder={`enter amount`}
              onChange={(e) =>
                props.setEntry({ ...props.entry, amount: e.target.value })
              }
              type={"number"}
              required
              readOnly={props.type === "delete" ? true : false}
            />

            <hr />
          </div>

          <div className="d-flex justify-content-around mb-2">
            {Loading ? (
              <span className="spinner-border text-primary"></span>
            ) : props.type === "delete" ? (
              <Button variant="danger" type="submit">
                Delete
              </Button>
            ) : (
              <Button variant="primary" type="submit">
                Save
              </Button>
            )}
            <Button
              variant="secondary"
              type="button"
              onClick={() => props.setShowModal(false)}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default PaySlipEntryCrud;
