import React from "react";

const NoPayments = () => {
  return (
    <tbody>
      <tr>
        <th>No Payments</th>

        <td>
          Payments are financial receipts from students eg tuition fee,
          accomodation fee etc
        </td>
      </tr>
    </tbody>
  );
};

export default NoPayments;
