import { createSlice } from "@reduxjs/toolkit";

export const ActiveSlice = createSlice({
  name: "active",
  initialState: {
    actives: [],
  },
  reducers: {
    fetchActives: (state, action) => {
      state.actives = action.payload;
    },
    addActive: (state, action) => {
      state.actives = [action.payload, ...state.actives];
    },
    updateActive: (state, action) => {
      let index = state.actives.findIndex(
        (active) => parseInt(active.linker) === parseInt(action.payload.linker)
      );

      let prevActives = [...state.actives];

      prevActives.splice(
        index < 0 ? 1 : index,
        index < 0 ? 0 : 1,
        action.payload
      );

      state.actives = prevActives;
    },
    deleteActive: (state, action) => {
      let newActives = state.actives.filter(
        (active) => parseInt(active.linker) !== parseInt(action.payload.linker)
      );
      state.actives = newActives;
    },
  },
});

export const { fetchActives, addActive, updateActive, deleteActive } =
  ActiveSlice.actions;

export default ActiveSlice.reducer;
