import React, { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { PlainList } from "flatlist-react";
import PaySlip from "../Admin/Staffs/StaffPaySlip/PaySlip";
import { StaffLivePayrollEntries } from "../../Network/PayrollEntryApi";
import { StaffLivePayrollItems } from "../../Network/PayrollItemApi";
import { FetchLivePayrollCats } from "../../Network/PayrollCatApi";

const MyPaySlips = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    FetchStaffData();
  }, []);

  const [ShowModal, setShowModal] = useState(false);
  const [Entry, setEntry] = useState({});
  const [Type, setType] = useState("add");
  const [Loading, setLoading] = useState(false);
  const User = useSelector((state) => state.auth.user);
  const ActiveCred = useSelector((state) => state.cred.activeCred);

  const FetchStaffData = async () => {
    setLoading(true);
    StaffLivePayrollItems(User.token, ActiveCred.instLinker, 0, dispatch);

    FetchLivePayrollCats(User.token, ActiveCred.instLinker, 0, dispatch);
    await StaffLivePayrollEntries(
      User.token,
      ActiveCred.instLinker,
      0,
      dispatch
    );
    setLoading(false);
  };

  const Items = useSelector((state) => state.staffPayItem.staffPayItems).filter(
    (item) => parseInt(item.deleted) === 0
  );

  const Entries = useSelector(
    (state) => state.staffPayEntry.staffPayEntries
  ).filter((item) => parseInt(item.deleted) === 0);

  const Months = [
    ...new Set(
      Entries.sort((a, b) => b.linker - a.linker).map((entry) => entry.month)
    ),
  ];

  return (
    <>
      <div className="row justify-content-center">
        {Loading ? (
          <div className="col-md-12">
            <p className="text-center text-primary">
              <span className="spinner-border spinner-border-lg"></span>
            </p>
          </div>
        ) : null}
        {Entries.length > 0 ? (
          <PlainList
            list={Months}
            renderOnScroll
            renderItem={(month) => (
              <div key={month} className="col-md-12 col-lg-6 col-xl-4">
                <PaySlip
                  setShowModal={setShowModal}
                  Entries={Entries.filter((entry) => entry.month === month)}
                  setEntry={setEntry}
                  month={month}
                  staff={ActiveCred || {}}
                  setType={setType}
                  Items={Items}
                ></PaySlip>
              </div>
            )}
          />
        ) : (
          <div className="col-md-12">
            <p className="text-center h6">No Payslips Found</p>
          </div>
        )}
      </div>
    </>
  );
};

export default MyPaySlips;
