import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PlainList } from "flatlist-react";
import { PencilSquare, Trash } from "react-bootstrap-icons";
import PaySlipEntryCrud from "../Staffs/StaffPaySlip/PaySlipEntryCrud";
import GeneratePdf from "../../../Utils/GeneratePdf";
import { FetchLivePayrollEntries } from "../../../Network/PayrollEntryApi";
import { FetchLivePayrollItems } from "../../../Network/PayrollItemApi";
import { FetchLivePayrollCats } from "../../../Network/PayrollCatApi";
import { Dropdown } from "react-bootstrap";

const PayrollSummary = () => {
	const [Search, setSearch] = useState("");
	const [ShowModal, setShowModal] = useState(false);
	const [Type, setType] = useState("all");
	const [IncomeType, setIncomeType] = useState("all");
	const [AllowanceType, setAllowanceType] = useState("all");
	const [DeductionType, setDeductionType] = useState("all");
	const [Item, setItem] = useState("all");
	const [Year, setYear] = useState("all");
	const [Month, setMonth] = useState("all");
	const [AddType, setAddType] = useState("add");
	const [Entry, setEntry] = useState({});
	//redux dispatch
	const dispatch = useDispatch();

	const User = useSelector((state) => state.auth.user);
	const ActiveCred = useSelector((state) => state.cred.activeCred);

	useEffect(() => {
		FetchLivePayrollCats(User.token, ActiveCred.instLinker, 0, dispatch);
		FetchLivePayrollItems(User.token, ActiveCred.instLinker, 0, dispatch);
		FetchLivePayrollEntries(User.token, ActiveCred.instLinker, 0, dispatch);
	}, []);

	const Staffs = useSelector((state) => state.cred.creds).filter(
		(staff) =>
			staff.credLinker != "0" &&
			staff.firstname &&
			parseInt(staff.deleted) === 0 &&
			(parseInt(staff.admin) === 1 || parseInt(staff.staff) === 1) &&
			((staff.staffReg || "").toLowerCase().search(Search.toLowerCase()) > -1 ||
				(staff.firstname || "").toLowerCase().search(Search.toLowerCase()) >
					-1 ||
				(staff.lastname || "").toLowerCase().search(Search.toLowerCase()) >
					-1 ||
				(staff.surname || "").toLowerCase().search(Search.toLowerCase()) > -1 ||
				(staff.email || "").toLowerCase().search(Search.toLowerCase()) > -1 ||
				(staff.contact || "").toLowerCase().search(Search.toLowerCase()) > -1 ||
				Search === "")
	);

	const Cats = useSelector((state) => state.payrollCat.payrollCats).filter(
		(item) => parseInt(item.deleted) === 0
	);

	const Items = useSelector((state) => state.payrollItem.payrollItems).filter(
		(item) => parseInt(item.deleted) === 0
	);

	const GetCat = (catLinker) => {
		let cat = Cats.find((cat) => parseInt(catLinker) === parseInt(cat.linker));

		return cat || { name: catLinker };
	};

	const GetItem = (itemLinker) => {
		let item = Items.find(
			(item) => parseInt(itemLinker) === parseInt(item.linker)
		);

		return item || { name: "unknown" };
	};

	const Entries = useSelector((state) => state.payrollEntry.payrollEntries)
		.filter((item) => parseInt(item.deleted) === 0)
		.sort((a, b) => b.linker - a.linker);

	const SearchedEntries = useSelector(
		(state) => state.payrollEntry.payrollEntries
	)
		.filter(
			(entry) =>
				parseInt(entry.deleted) === 0 &&
				Staffs.some(
					(staff) => parseInt(staff.linker) === parseInt(entry.staffLinker)
				) &&
				(Year === "all" ||
					parseInt(entry.month.split("-")[0]) === parseInt(Year)) &&
				(Month === "all" ||
					parseInt(entry.month.split("-")[1]) === parseInt(Month))
		)
		.sort((a, b) => b.linker - a.linker);

	const SearchedAllowance = SearchedEntries.filter(
		(entry) =>
			(AllowanceType === "all" ||
				parseInt(GetItem(entry.itemLinker).catLinker) ===
					parseInt(AllowanceType)) &&
			Items.filter((item) =>
				Cats.filter((cat) => cat.type === "Allowance").some(
					(cat) => parseInt(cat.linker) === parseInt(item.catLinker)
				)
			).some((item) => parseInt(item.linker) === parseInt(entry.itemLinker))
	);

	const SearchedIncomes = SearchedEntries.filter(
		(entry) =>
			(IncomeType === "all" ||
				parseInt(GetItem(entry.itemLinker).catLinker) ===
					parseInt(IncomeType)) &&
			Items.filter((item) =>
				Cats.filter((cat) => cat.type === "Income").some(
					(cat) => parseInt(cat.linker) === parseInt(item.catLinker)
				)
			).some((item) => parseInt(item.linker) === parseInt(entry.itemLinker))
	);

	const SearchedDeductions = SearchedEntries.filter(
		(entry) =>
			(DeductionType === "all" ||
				parseInt(GetItem(entry.itemLinker).catLinker) ===
					parseInt(DeductionType)) &&
			Items.filter((item) =>
				Cats.filter((cat) => cat.type === "Deduction").some(
					(cat) => parseInt(cat.linker) === parseInt(item.catLinker)
				)
			).some((item) => parseInt(item.linker) === parseInt(entry.itemLinker))
	);

	const GetStaffMonthTypeEntries = (type, month, staffLinker) => {
		return (
			type === "Income"
				? SearchedIncomes
				: type === "Allowance"
				? SearchedAllowance
				: type === "Deduction"
				? SearchedDeductions
				: SearchedEntries
		).filter(
			(entry) =>
				parseInt(staffLinker) === parseInt(entry.staffLinker) &&
				entry.month === month &&
				Items.filter((item) =>
					Cats.filter((cat) => cat.type === type).some(
						(cat) => parseInt(cat.linker) === parseInt(item.catLinker)
					)
				).some((item) => parseInt(item.linker) === parseInt(entry.itemLinker))
		);
	};

	return (
		<div>
			<div className="d-flex justify-content-around mt-1">
				<p className="text-center h6 text-primary"> Payroll Summary </p>
				<GeneratePdf
					id={`payroll-summary`}
					name={`payroll-summary-${Month}-${Year}-${Type}`}
				></GeneratePdf>
			</div>

			<table className="table table-sm" id="payroll-summary">
				<thead>
					<tr>
						<th colSpan={2}>
							<input
								type={"text"}
								placeholder={`Search Staff`}
								value={Search}
								onChange={(e) => setSearch(e.target.value)}
								className="rounded form-control"
							/>
						</th>
						<th>Details</th>
						<th>
							<select
								className="form-control rounded"
								value={IncomeType}
								onChange={(e) => setIncomeType(e.target.value)}
							>
								<option value="all">All Incomes</option>
								{Cats.filter((cat) => cat.type === "Income").map((item) => (
									<option value={item.linker}>{item.name}</option>
								))}
							</select>
						</th>
						<th>
							<select
								className="form-control rounded"
								value={AllowanceType}
								onChange={(e) => setAllowanceType(e.target.value)}
							>
								<option value="all">All Allowances</option>
								{Cats.filter((cat) => cat.type === "Allowance").map((item) => (
									<option value={item.linker}>{item.name}</option>
								))}
							</select>
						</th>
						<th>
							<select
								className="form-control rounded"
								value={DeductionType}
								onChange={(e) => setDeductionType(e.target.value)}
							>
								<option value="all">All Deductions</option>
								{Cats.filter((cat) => cat.type === "Deduction").map((item) => (
									<option value={item.linker}>{item.name}</option>
								))}
							</select>
						</th>
						<th>Net Pay</th>
						<th>
							{" "}
							<th>
								{" "}
								<select
									className="rounded"
									value={Year}
									onChange={(e) => setYear(e.target.value)}
								>
									<option value="all">All Yrs</option>
									{[
										...new Set(
											Entries.sort((a, b) => b.linker - a.linker).map(
												(entry) => entry.month.split("-")[0]
											)
										),
									].map((year) => (
										<option value={year}>{year}</option>
									))}
								</select>
							</th>
						</th>
					</tr>
					<tr>
						<th>No</th>
						<th>Name</th>
						<th></th>
						<th>
							<th>{SearchedIncomes.reduce((a, b) => +a + +b.amount, 0)}</th>
						</th>
						<th>
							<th>{SearchedAllowance.reduce((a, b) => +a + +b.amount, 0)}</th>
						</th>
						<th> {SearchedDeductions.reduce((a, b) => +a + +b.amount, 0)}</th>
						<th>
							{" "}
							{SearchedIncomes.reduce((a, b) => +a + +b.amount, 0) +
								SearchedAllowance.reduce((a, b) => +a + +b.amount, 0) -
								SearchedDeductions.reduce((a, b) => +a + +b.amount, 0)}
						</th>

						<th>
							{" "}
							<select
								className="rounded"
								value={Month}
								onChange={(e) => setMonth(e.target.value)}
							>
								<option value="all">All Mths</option>
								{[
									...new Set(
										Entries.sort((a, b) => b.linker - a.linker).map(
											(entry) => entry.month.split("-")[1]
										)
									),
								].map((month) => (
									<option value={month}>{month}</option>
								))}
							</select>
						</th>
					</tr>
				</thead>
				<tbody>
					<PlainList
						list={[
							...new Set(
								SearchedEntries.sort((a, b) => b.linker - a.linker).map(
									(entry) => entry.month
								)
							),
						]}
						renderOnScroll
						renderItem={(month) => (
							<PlainList
								list={Staffs}
								renderOnScroll
								renderItem={(staff) => (
									<tr>
										<td>{staff.staffReg}</td>
										<td>
											{staff.firstname} {staff.lastname}
										</td>
										<td>
											<small>
												{[
													...GetStaffMonthTypeEntries(
														"Income",
														month,
														staff.linker
													),
													...GetStaffMonthTypeEntries(
														"Allowance",
														month,
														staff.linker
													),
													...GetStaffMonthTypeEntries(
														"Deduction",
														month,
														staff.linker
													),
												].map((entry) => (
													<>{entry.details}, </>
												))}
											</small>
										</td>

										<td>
											<Dropdown style={{ width: "100%" }}>
												<Dropdown.Toggle variant="transparent">
													{GetStaffMonthTypeEntries(
														"Income",
														month,
														staff.linker
													).reduce((a, b) => +a + +b.amount, 0)}
												</Dropdown.Toggle>
												<Dropdown.Menu>
													<table className="table-bordered">
														{GetStaffMonthTypeEntries(
															"Income",
															month,
															staff.linker
														).map((entry) => (
															<tr>
																<td>
																	{
																		GetCat(GetItem(entry.itemLinker).catLinker)
																			.name
																	}
																</td>
																<td>{entry.amount}</td>{" "}
																<td>
																	{" "}
																	<PencilSquare
																		onClick={() => {
																			setShowModal(true);
																			setAddType("edit");
																			setEntry({
																				...entry,
																				catLinker: GetItem(entry.itemLinker)
																					.catLinker,
																			});
																		}}
																		style={{ cursor: "pointer" }}
																		className="text-primary mx-1"
																	/>
																	<Trash
																		onClick={() => {
																			setShowModal(true);
																			setAddType("delete");
																			setEntry({
																				...entry,
																				catLinker: GetItem(entry.itemLinker)
																					.catLinker,
																			});
																		}}
																		style={{ cursor: "pointer" }}
																		className="text-danger mx-1"
																	/>
																</td>
															</tr>
														))}
													</table>
												</Dropdown.Menu>
											</Dropdown>
										</td>
										<td>
											<Dropdown style={{ width: "100%" }}>
												<Dropdown.Toggle variant="transparent">
													{GetStaffMonthTypeEntries(
														"Allowance",
														month,
														staff.linker
													).reduce((a, b) => +a + +b.amount, 0)}
												</Dropdown.Toggle>
												<Dropdown.Menu>
													<table className="table-bordered">
														{GetStaffMonthTypeEntries(
															"Allowance",
															month,
															staff.linker
														).map((entry) => (
															<tr>
																<td>
																	{
																		GetCat(GetItem(entry.itemLinker).catLinker)
																			.name
																	}
																</td>{" "}
																<td>{entry.amount}</td>{" "}
																<td>
																	{" "}
																	<PencilSquare
																		onClick={() => {
																			setShowModal(true);
																			setAddType("edit");
																			setEntry({
																				...entry,
																				catLinker: GetItem(entry.itemLinker)
																					.catLinker,
																			});
																		}}
																		style={{ cursor: "pointer" }}
																		className="text-primary mx-1"
																	/>
																	<Trash
																		onClick={() => {
																			setShowModal(true);
																			setAddType("delete");
																			setEntry({
																				...entry,
																				catLinker: GetItem(entry.itemLinker)
																					.catLinker,
																			});
																		}}
																		style={{ cursor: "pointer" }}
																		className="text-danger mx-1"
																	/>
																</td>
															</tr>
														))}
													</table>
												</Dropdown.Menu>
											</Dropdown>
										</td>
										<td>
											<Dropdown style={{ width: "100%" }}>
												<Dropdown.Toggle variant="transparent">
													{GetStaffMonthTypeEntries(
														"Deduction",
														month,
														staff.linker
													).reduce((a, b) => +a + +b.amount, 0)}
												</Dropdown.Toggle>
												<Dropdown.Menu>
													<table className="table-bordered">
														{GetStaffMonthTypeEntries(
															"Deduction",
															month,
															staff.linker
														).map((entry) => (
															<tr>
																<td>
																	{
																		GetCat(GetItem(entry.itemLinker).catLinker)
																			.name
																	}
																</td>{" "}
																<td>{entry.amount}</td>{" "}
																<td>
																	{" "}
																	<PencilSquare
																		onClick={() => {
																			setShowModal(true);
																			setAddType("edit");
																			setEntry({
																				...entry,
																				catLinker: GetItem(entry.itemLinker)
																					.catLinker,
																			});
																		}}
																		style={{ cursor: "pointer" }}
																		className="text-primary mx-1"
																	/>
																	<Trash
																		onClick={() => {
																			setShowModal(true);
																			setAddType("delete");
																			setEntry({
																				...entry,
																				catLinker: GetItem(entry.itemLinker)
																					.catLinker,
																			});
																		}}
																		style={{ cursor: "pointer" }}
																		className="text-danger mx-1"
																	/>
																</td>
															</tr>
														))}
													</table>
												</Dropdown.Menu>
											</Dropdown>
										</td>
										<td>
											{GetStaffMonthTypeEntries(
												"Income",
												month,
												staff.linker
											).reduce((a, b) => +a + +b.amount, 0) +
												GetStaffMonthTypeEntries(
													"Allowance",
													month,
													staff.linker
												).reduce((a, b) => +a + +b.amount, 0) -
												GetStaffMonthTypeEntries(
													"Deduction",
													month,
													staff.linker
												).reduce((a, b) => +a + +b.amount, 0)}
										</td>
										<td>{month}</td>
									</tr>
								)}
							/>
						)}
					/>
				</tbody>
			</table>
			<PaySlipEntryCrud
				entry={Entry}
				setEntry={setEntry}
				type={AddType}
				ShowModal={ShowModal}
				setShowModal={setShowModal}
			></PaySlipEntryCrud>
		</div>
	);
};

export default PayrollSummary;
