import swal from "sweetalert";
import {
  addPayrollItem,
  fetchPayrollItems,
  updatePayrollItem,
} from "../reducers/PayrollItemSlice";
import { ApiUrl } from "./Urls";
import { fetchStaffPayItems } from "../reducers/StaffPayItemSlice";

export const FetchPayrollItems = async (
  token,
  instLinker,
  dispatch,
  online,
  from = "fetch",
  payload = {}
) => {
  if (from === "fetch") {
    FetchLivePayrollItems(token, instLinker, online, dispatch);
  } else if (parseInt(instLinker) === parseInt(payload.instLinker)) {
    dispatch(updatePayrollItem(payload));
  }
};

export const addLivePayrollItem = (
  payrollItem,
  instLinker,
  token,
  dispatch
) => {
  return fetch(`${ApiUrl}/payroll-items/add`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    },
    body: JSON.stringify({
      ...payrollItem,
      instLinker,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 201) {
        dispatch(updatePayrollItem(res.payrollItem));
      }
    })
    .catch(() => {
      swal({
        title: "Failed to connect",
        icon: "warning",
        timer: 3000,
      });
    });
};

export const updateLivePayrollItem = (payrollItem, token, dispatch) => {
  return fetch(`${ApiUrl}/payroll-items/edit`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    },
    body: JSON.stringify({
      ...payrollItem,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 200) {
        dispatch(updatePayrollItem(res.payrollItem));
      }
    })
    .catch(() => {
      swal({
        title: "Failed to connect",
        icon: "warning",
        timer: 3000,
      });
    });
};

export const FetchLivePayrollItems = (token, instLinker, online, dispatch) => {
  fetch(`${ApiUrl}/payroll-items/get`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    },
    body: JSON.stringify({
      online,
      instLinker,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 200) {
        dispatch(fetchPayrollItems(res.payrollItems));
      }
    })
    .catch(() => {});
};

export const StaffLivePayrollItems = (token, instLinker, online, dispatch) => {
  return fetch(`${ApiUrl}/payroll-items/staff`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    },
    body: JSON.stringify({
      online,
      instLinker,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 200) {
        dispatch(fetchStaffPayItems(res.payrollItems));
      }
    })
    .catch(() => {});
};
