import { createSlice } from "@reduxjs/toolkit";

export const ModeSlice = createSlice({
  name: "mode",
  initialState: {
    modes: [],
  },
  reducers: {
    fetchModes: (state, action) => {
      state.modes = action.payload;
    },
    addMode: (state, action) => {
      state.modes = [action.payload, ...state.modes];
    },
    updateMode: (state, action) => {
      let index = state.modes.findIndex(
        (mode) => parseInt(mode.linker) === parseInt(action.payload.linker)
      );

      let prevModes = [...state.modes];

      prevModes.splice(
        index < 0 ? 1 : index,
        index < 0 ? 0 : 1,
        action.payload
      );

      state.modes = prevModes;
    },
    deleteMode: (state, action) => {
      let newModes = state.modes.filter(
        (mode) => parseInt(mode.linker) !== parseInt(action.payload.linker)
      );
      state.modes = newModes;
    },
  },
});

export const { fetchModes, addMode, updateMode, deleteMode } =
  ModeSlice.actions;

export default ModeSlice.reducer;
