import { createSlice } from "@reduxjs/toolkit";

export const MpesaPaymentsSlice = createSlice({
	name: "mpesaPayment",
	initialState: {
		mpesaPayments: [],
	},
	reducers: {
		fetchMpesaPayments: (state, action) => {
			state.mpesaPayments = action.payload;
		},
		updateMpesaPayment: (state, action) => {
			let index = state.mpesaPayments.findIndex(
				(payment) =>
					parseInt(payment.linker) === parseInt(action.payload.linker)
			);

			let prevPayments = [...state.mpesaPayments];

			prevPayments.splice(
				index < 0 ? 1 : index,
				index < 0 ? 0 : 1,
				action.payload
			);

			state.mpesaPayments = prevPayments;
		},
	},
});

export const { fetchMpesaPayments, updateMpesaPayment } =
	MpesaPaymentsSlice.actions;

export default MpesaPaymentsSlice.reducer;
