import React, { useEffect, useState } from "react";
import { ApiUrl } from "../../Network/Urls";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addSub } from "../../reducers/SubSlice";
import ManualPay from "./ManualPay";

const Purchase = ({ softwareId, amount, from }) => {
  useEffect(() => {
    // LoadScript(); to be activated after paypal approval
  }, []);

  const [Loading, setLoading] = useState(false);
  const [Code, setCode] = useState("");
  const inst = useSelector((state) => state.inst.activeInst);

  const navigate = useNavigate();
  //redux dispatch
  const dispatch = useDispatch();

  const CheckTransaction = (orderID) => {
    setLoading(true);
    fetch(`${ApiUrl}/api/orders/capture`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        orderID,
        softwareId,
        amount: parseInt(amount),
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        if (res.status != 201) {
          swal({
            title: "Failed to connect",
            icon: "warning",
            timer: 3000,
          });
          return;
        }
        if (from === "web") {
          dispatch(addSub(res.sub));
          navigate(`/${inst.name}`);
        } else {
          setCode(res.sub.code);
        }
      })
      .catch((err) => {
        setLoading(false);
        swal({
          title: "Failed to connect",
          icon: "warning",
          timer: 3000,
        });
      });
  };
  const LoadScript = async () => {
    setLoading(true);
    /*    var PAYPAL_SCRIPT =
      "https://www.paypal.com/sdk/js?client-id=AZYfGer7D4VDMYYLX9XMDgZZ1iPjNhwe1PBY70ReUHQrmbSYpzlNaqxRUFDnX6CrsWYYNnHY9jBxfMuq&currency=USD";*/
    var PAYPAL_SCRIPT =
      "https://www.paypal.com/sdk/js?client-id=AeKZKUMcGGD_-DUp2ZA6USledptiabWHRDYWJtkFioLn8CPHnuZHQUIFShICt2t4LjW3V27UNteDDf4B&currency=USD";
    var script = await document.createElement("script");
    await script.setAttribute("src", PAYPAL_SCRIPT);
    await document.head.appendChild(script);
    script.onload = () => RenderButtons();
  };

  const RenderButtons = () => {
    setLoading(false);
    window.paypal
      .Buttons({
        createOrder: (data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  currency_code: "USD",
                  value: amount,
                },
              },
            ],
          });
        },
        onApprove: (data, actions) => {
          return actions.order.capture().then((details) => {
            if (details.status === "COMPLETED") {
              CheckTransaction(details.id);
            } else {
              swal({
                title: "Transaction Failed",
                icon: "warning",
                text: "Try again",
                timer: 5000,
              });
            }
          });
        },
      })
      .render("#paypal-buttons-container");
  };

  return (
    <div>
      {Loading ? (
        <p
          className="text-center"
          onClick={() => {
            //  CheckTransaction(`test-${Date.now()}`);
          }}
        >
          <span className="spinner-border text-primary"></span>{" "}
        </p>
      ) : Code !== "" && from === "software" ? (
        <p className="text-center">
          Activation Code is <strong>{Code}</strong>
        </p>
      ) : null}
      <div id="paypal-buttons-container"></div>
      <ManualPay softwareId={softwareId}></ManualPay>
    </div>
  );
};

export default Purchase;
