import { createSlice } from "@reduxjs/toolkit";

export const OnlineSlice = createSlice({
  name: "online",
  initialState: {
    online: 0,
  },
  reducers: {
    updateOnline: (state, action) => {
      state.online = action.payload;
    },
  },
});

export const { updateOnline } = OnlineSlice.actions;

export default OnlineSlice.reducer;
