import { createSlice } from "@reduxjs/toolkit";

export const PayrollCatSlice = createSlice({
  name: "payrollCat",
  initialState: {
    payrollCats: [],
  },
  reducers: {
    fetchPayrollCats: (state, action) => {
      state.payrollCats = action.payload;
    },
    addPayrollCat: (state, action) => {
      state.payrollCats = [action.payload, ...state.payrollCats];
    },
    updatePayrollCat: (state, action) => {
      let index = state.payrollCats.findIndex(
        (payrollCat) =>
          parseInt(payrollCat.linker) === parseInt(action.payload.linker)
      );

      let prevPayrollCats = [...state.payrollCats];

      prevPayrollCats.splice(
        index < 0 ? 1 : index,
        index < 0 ? 0 : 1,
        action.payload
      );

      state.payrollCats = prevPayrollCats;
    },
    deletePayrollCat: (state, action) => {
      let newPayrollCats = state.payrollCats.filter(
        (payrollCat) =>
          parseInt(payrollCat.linker) !== parseInt(action.payload.linker)
      );
      state.payrollCats = newPayrollCats;
    },
  },
});

export const {
  fetchPayrollCats,
  addPayrollCat,
  updatePayrollCat,
  deletePayrollCat,
} = PayrollCatSlice.actions;

export default PayrollCatSlice.reducer;
