import React from "react";

const NoInstitutions = () => {
  return (
    <div
      className="col-12 col-md-12 col-lg-12 jumbotron"
      style={{ marginTop: "25vh" }}
    >
      <p
        className="h4 text-light text-center"
        style={{ fontFamily: "sans-serif" }}
      >
        Seems like you do not have any institutions you can go ahead and
        register new institution
      </p>
    </div>
  );
};

export default NoInstitutions;
