import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  addLiveExpense,
  updateLiveExpense,
} from "../../../../Network/ExpenseApi";

const ExpenseCrud = (props) => {
  //redux dispatch
  const dispatch = useDispatch();

  const User = useSelector((state) => state.auth.user);
  const online = useSelector((state) => state.online.online);
  const ActiveCred = useSelector((state) => state.cred.activeCred);
  const Modes = useSelector((state) => state.mode.modes).filter(
    (mode) => parseInt(mode.deleted) === 0
  );
  const [Loading, setLoading] = useState(false);

  //edit expense
  const EditExpense = async () => {
    let {
      credLinker,

      trace,

      deleted,
    } = props.expense;

    trace = Date.now();
    credLinker = ActiveCred.linker;
    deleted = props.type === "delete" ? 1 : deleted;

    await updateLiveExpense(
      { ...props.expense, trace, credLinker, deleted },
      User.token,
      dispatch
    );
  };

  const SaveExpense = async () => {
    let trace = Date.now();

    await addLiveExpense(
      {
        ...props.expense,
        instLinker: ActiveCred.linker,
        credLinker: ActiveCred.linker,
        live: 1,
        status: 0,
        trace,
        deleted: 0,
      },
      ActiveCred.instLinker,
      User.token,
      dispatch
    );
  };

  const HandleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (props.type === "add") {
      await SaveExpense();
    } else {
      await EditExpense();
    }
    setLoading(false);

    props.setShowModal(false);
  };

  return (
    <Modal
      show={props.ShowModal}
      onHide={() => props.setShowModal(false)}
      backdrop="static"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <p className="text-capitalize text-center">{props.type} Expense</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={HandleSubmit}>
          {props.Cats.length > 0 ? (
            <div className="form-group">
              <label className="mb-2 text-capitalize">Expense Category:</label>
              <select
                className="rounded form-control"
                value={props.expense.catLinker}
                onChange={(e) =>
                  props.setExpense({
                    ...props.expense,
                    catLinker: e.target.value,
                  })
                }
                required
                readOnly={props.type === "delete" ? true : false}
              >
                <option value={""}>Select Expense Category</option>
                {props.Cats.map((cat, index) => (
                  <option key={index} value={cat.linker}>
                    {cat.name}
                  </option>
                ))}
              </select>
              <hr />
            </div>
          ) : null}
          <div className="form-group">
            <label className="mb-2 text-capitalize"> Expense For:</label>
            <input
              className="rounded form-control"
              placeholder={`enter expense`}
              value={props.expense.name}
              onChange={(e) =>
                props.setExpense({
                  ...props.expense,
                  name: e.target.value,
                })
              }
              required
              readOnly={props.type === "delete" ? true : false}
            />
            <hr />
          </div>
          <div className="form-group">
            <label className="mb-2 text-capitalize">Expense Details:</label>
            <textarea
              className="rounded form-control"
              placeholder={`enter expense details`}
              value={props.expense.details}
              onChange={(e) =>
                props.setExpense({
                  ...props.expense,
                  details: e.target.value,
                })
              }
              required
              readOnly={props.type === "delete" ? true : false}
            />
            <hr />
          </div>
          {Modes.length > 0 ? (
            <div className="form-group">
              <label className="mb-2 text-capitalize">Mode Of Payment:</label>
              <select
                className="rounded form-control"
                value={props.expense.mode}
                onChange={(e) =>
                  props.setExpense({
                    ...props.expense,
                    mode: e.target.value,
                  })
                }
                required
                readOnly={props.type === "delete" ? true : false}
              >
                <option value={""}>Select Expense Mode</option>
                {Modes.map((mode, index) => (
                  <option key={index} value={mode.linker}>
                    {mode.name}
                  </option>
                ))}
              </select>
              <hr />
            </div>
          ) : null}
          <div className="form-group">
            <label className="mb-2 text-capitalize">Expense Amount:</label>
            <input
              className="rounded form-control"
              placeholder={`enter expense amount`}
              value={props.expense.amount}
              onChange={(e) =>
                props.setExpense({
                  ...props.expense,
                  amount: e.target.value,
                })
              }
              type="number"
              required
              readOnly={props.type === "delete" ? true : false}
            />
            <hr />
          </div>
          <div className="form-group">
            <label className="mb-2 text-capitalize">
              Transaction/Receipt No:
            </label>
            <input
              className="rounded form-control"
              placeholder={`enter trasaction/receipt No`}
              value={props.expense.code}
              onChange={(e) =>
                props.setExpense({
                  ...props.expense,
                  code: e.target.value,
                })
              }
              type="text"
              required
              readOnly={props.type === "delete" ? true : false}
            />
            <hr />
          </div>
          <div className="d-flex justify-content-around mb-2">
            {Loading ? (
              <span className="spinner-border text-primary"></span>
            ) : props.type === "delete" ? (
              <Button variant="danger" type="submit">
                Delete
              </Button>
            ) : (
              <Button variant="primary" type="submit">
                Save
              </Button>
            )}
            <Button
              variant="secondary"
              type="button"
              onClick={() => props.setShowModal(false)}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ExpenseCrud;
