import React from "react";

const NoPayrollItems = () => {
  return (
    <tbody>
      <tr>
        <td>No Payroll Items</td>

        <td>
          You have not added any payroll items for the staff ie. incomes,
          allowances and deductions.They help in generating PaySlip
        </td>
      </tr>
    </tbody>
  );
};

export default NoPayrollItems;
