import React, { useState, useEffect } from "react";
import ExpenseCrud from "./ExpenseCrud";
import { useDispatch, useSelector } from "react-redux";
import NoExpense from "./NoExpense";
import ExpenseDisp from "./ExpenseDisp";
import { FetchExpenses } from "../../../../Network/ExpenseApi";
import { PlainList } from "flatlist-react";
import GeneratePdf from "../../../../Utils/GeneratePdf";

const Expenses = () => {
  useEffect(() => {
    FetchExpenses(User.token, ActiveCred.instLinker, dispatch, online);
  }, []);

  const [ShowModal, setShowModal] = useState(false);
  const [Expense, setExpense] = useState({ name: "" });
  const [Search, setSearch] = useState("");
  const [Type, setType] = useState("add");
  const [By, setBy] = useState("all");
  const [Day, setDay] = useState("all");
  const [Month, setMonth] = useState("all");
  const [Year, setYear] = useState("all");
  const [Mode, setMode] = useState("all");
  const [Cat, setCat] = useState("all");
  const [Loading, setLoading] = useState(false);

  //redux dispatch
  const dispatch = useDispatch();

  const User = useSelector((state) => state.auth.user);
  const online = useSelector((state) => state.online.online);
  const ActiveCred = useSelector((state) => state.cred.activeCred);
  const Expenses = useSelector((state) => state.expense.expenses);
  const Creds = useSelector((state) => state.cred.creds);
  const Modes = useSelector((state) => state.mode.modes).filter(
    (mode) => parseInt(mode.deleted) === 0
  );
  const Cats = useSelector((state) => state.expenseCat.expenseCats).filter(
    (cat) => parseInt(cat.deleted) === 0
  );

  const FindUser = (linker) => {
    let cred = Creds.find((cred) => parseInt(cred.linker) == parseInt(linker));

    return cred || { email: "unknown" };
  };

  const SearchExpense = Expenses.filter(
    (expense) =>
      (expense.name.toLowerCase().search(Search.toLowerCase()) > -1 ||
        expense.details.toLowerCase().search(Search.toLowerCase()) > -1 ||
        Search === "") &&
      parseInt(expense.deleted) === 0 &&
      (parseInt(expense.mode) === parseInt(Mode) || Mode === "all") &&
      (parseInt(expense.catLinker) === parseInt(Cat) || Cat === "all") &&
      (parseInt(
        new Date(parseInt(expense.linker)).toLocaleDateString().split("/")[2]
      ) === parseInt(Year) ||
        Year === "all") &&
      (parseInt(
        new Date(parseInt(expense.linker)).toLocaleDateString().split("/")[0]
      ) === parseInt(Month) ||
        Year === "all" ||
        Month === "all") &&
      (parseInt(
        new Date(parseInt(expense.linker)).toLocaleDateString().split("/")[1]
      ) === parseInt(Day) ||
        Year === "all" ||
        Month === "all" ||
        Day == "all") &&
      (parseInt(expense.credLinker) === parseInt(By) || By === "all")
  ).sort((a, b) => parseInt(b.linker) - parseInt(a.linker));

  return (
    <div>
      <div className="d-flex justify-content-around mt-2">
        <p className="text-center h6 text-primary text-capitalize">
          {SearchExpense.length} Expenses
        </p>{" "}
        <button
          className="btn btn-sm btn-outline-secondary"
          onClick={() => {
            setType("add");
            setShowModal(true);
            setExpense({
              linker: Date.now(),
              trace: Date.now(),
              name: "",
              details: "",
              amount: "",
              code: "",
              catLinker: "",
              deleted: "0",
            });
          }}
        >
          Add Expense
        </button>
      </div>

      <table className="table table-sm" id="expenses">
        <thead className="thead-dark">
          <tr>
            <th scope="column" colSpan={2}>
              <input
                type={"text"}
                placeholder={`Search Expense`}
                value={Search}
                onChange={(e) => setSearch(e.target.value)}
                className="rounded form-control"
              />
            </th>
            <th scope="colum">Mode</th>
            <th scope="column">Amount</th>
            <th scope="column">
              <select
                className="form-control rounded"
                value={Cat}
                onChange={(e) => setCat(e.target.value)}
              >
                <option value={"all"}>All Categories</option>
                {Cats.map((cat, index) => (
                  <option key={index} value={cat.linker}>
                    {cat.name}
                  </option>
                ))}
              </select>
            </th>
            <th scope="column">Date</th>
            <th scope="column">By</th>
          </tr>
          <tr>
            <th scope="column">Expense</th>
            <th scope="column">
              <select
                className="form-control rounded"
                value={Mode}
                onChange={(e) => setMode(e.target.value)}
              >
                <option value={"all"}>All Modes</option>
                {Modes.map((mode, index) => (
                  <option key={index} value={mode.linker}>
                    {mode.name}
                  </option>
                ))}
              </select>
            </th>
            <th scope="column">Details</th>
            <th>{SearchExpense.reduce((a, b) => +a + +b.amount, 0)}</th>
            <th colSpan={2}>
              <div className="d-flex">
                <select
                  className="form-control rounded"
                  value={Year}
                  onChange={(e) => setYear(e.target.value)}
                >
                  <option value={"all"}>All Yrs</option>
                  {[
                    ...new Set(
                      Expenses.map(
                        (expense) =>
                          new Date(parseInt(expense.linker))
                            .toLocaleDateString()
                            .split("/")[2]
                      )
                    ),
                  ].map((yr) => (
                    <option key={yr} value={yr}>
                      {yr}
                    </option>
                  ))}
                </select>
                {Year !== "all" ? (
                  <select
                    className="form-control rounded"
                    value={Month}
                    onChange={(e) => setMonth(e.target.value)}
                  >
                    <option value={"all"}>All Mths</option>
                    {[
                      ...new Set(
                        Expenses.map(
                          (expense) =>
                            new Date(parseInt(expense.linker))
                              .toLocaleDateString()
                              .split("/")[0]
                        )
                      ),
                    ].map((mth) => (
                      <option key={mth} value={mth}>
                        {mth}
                      </option>
                    ))}
                  </select>
                ) : null}
                {Month !== "all" && Year !== "all" ? (
                  <select
                    className="form-control rounded"
                    value={Day}
                    onChange={(e) => setDay(e.target.value)}
                  >
                    <option value={"all"}>All Dates</option>
                    {[
                      ...new Set(
                        Expenses.map(
                          (expense) =>
                            new Date(parseInt(expense.linker))
                              .toLocaleDateString()
                              .split("/")[1]
                        )
                      ),
                    ].map((day) => (
                      <option key={day} value={day}>
                        {day}
                      </option>
                    ))}
                  </select>
                ) : null}
              </div>
            </th>
            <th>
              <select
                className="form-control rounded"
                value={By}
                onChange={(e) => setBy(e.target.value)}
              >
                <option value={"all"}>All Staffs</option>
                <PlainList
                  list={[
                    ...new Set(Expenses.map((expense) => expense.credLinker)),
                  ]}
                  renderOnScroll
                  renderItem={(staff) => (
                    <option value={staff} key={staff}>
                      {FindUser(staff).email}
                    </option>
                  )}
                />
              </select>
            </th>
          </tr>
        </thead>
        {SearchExpense.length > 0 ? (
          <tbody>
            <PlainList
              list={SearchExpense}
              renderOnScroll
              renderItem={(expense) => (
                <ExpenseDisp
                  setShowModal={setShowModal}
                  ShowModal={ShowModal}
                  expense={expense}
                  setExpense={setExpense}
                  type={Type}
                  setType={setType}
                  key={expense.linker}
                  Cats={Cats}
                ></ExpenseDisp>
              )}
            />
          </tbody>
        ) : (
          <NoExpense></NoExpense>
        )}
      </table>
      <GeneratePdf id={"expenses"} name={"expenses"}></GeneratePdf>
      <ExpenseCrud
        setShowModal={setShowModal}
        ShowModal={ShowModal}
        expense={Expense}
        setExpense={setExpense}
        type={Type}
        Cats={Cats}
      ></ExpenseCrud>
    </div>
  );
};

export default Expenses;
