import React from "react";
import { useSelector } from "react-redux";
import { Trash, PencilSquare } from "react-bootstrap-icons";

const EntryDisp = ({
  entry,
  setShowModal,
  setType,
  setEntry,
  Products,
  setEntryProduct,
}) => {
  const Creds = useSelector((state) => state.cred.creds);

  const FindUser = (linker) => {
    let cred = Creds.find((cred) => parseInt(cred.linker) == parseInt(linker));

    return cred || { firstname: "unknown" };
  };

  const FindProduct = (linker) => {
    let Product = Products.find(
      (product) => parseInt(product.linker) === parseInt(linker)
    );
    return Product || { name: "unknown" };
  };

  return (
    <tr className="item">
      <td>{FindProduct(entry.productLinker).name}</td>
      <td>{entry.type}</td>
      <td>{entry.supplier}</td>
      <td>{entry.details}</td>
      <td>{entry.amount}</td>
      <td>
        {entry.type === "sale" ||
        entry.type === "return-purchase" ||
        entry.type === "damaged" ? (
          <span className="text-danger">- {entry.quantity}</span>
        ) : (
          <span className="text-success">+ {entry.quantity}</span>
        )}
      </td>
      <td>{entry.total}</td>
      <td>{new Date(parseInt(entry.linker)).toLocaleDateString("en-US")}</td>
      <td>{FindUser(entry.credLinker).email}</td>
      <td>
        <PencilSquare
          onClick={() => {
            setShowModal(true);
            setType("edit");
            setEntry({ ...entry });
            setEntryProduct(FindProduct(entry.productLinker));
          }}
          style={{ cursor: "pointer" }}
          className="text-info mx-2"
        />

        <Trash
          onClick={() => {
            setShowModal(true);
            setType("delete");
            setEntry({ ...entry });
            setEntryProduct(FindProduct(entry.productLinker));
          }}
          style={{ cursor: "pointer" }}
          className="text-danger mx-2"
        />
      </td>
    </tr>
  );
};

export default EntryDisp;
