import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import { updateLiveInst } from "../../Network/InstApi";

const EditProfile = (props) => {
  const User = useSelector((state) => state.auth.user);
  const inst = useSelector((state) => state.inst.activeInst);
  const navigate = useNavigate();

  const [Name, setName] = useState(inst.name);
  const [Higher, setHigher] = useState(inst.higher);
  const [Loading, setLoading] = useState(false);

  useEffect(() => {
    setName(inst.name);
    setHigher(inst.higher);
  }, [inst]);

  //redux dispatch
  const dispatch = useDispatch();

  const HandleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let linker = Date.now();

    await updateLiveInst(
      { ...inst, name: Name, higher: Higher, trace: linker },
      User.token,
      dispatch
    );
    setLoading(false);
    navigate(`/${Name}`);
  };

  return (
    <div className="row justify-content-center">
      <div className="col-md-6">
        <div className="card p-2">
          <div className="card-header">
            <p className="card-title text-center text-primary text-capitalize">
              Edit {inst.name}
            </p>
          </div>
          <Form onSubmit={HandleSubmit}>
            <div className="form-group">
              <label className="mb-2 text-capitalize">Institution Name:</label>
              <input
                className="rounded form-control"
                placeholder={`enter institution name`}
                value={Name}
                onChange={(e) => setName(e.target.value)}
                required
              />
              <hr />
            </div>
            <div className="form-group">
              <label className="mb-2 text-capitalize"> Institution Type:</label>
              <select
                className="rounded form-control"
                onChange={(e) => setHigher(e.target.value)}
                value={parseInt(Higher)}
              >
                <option value={0}>Select Type</option>
                <option value={1}>Higher Education</option>
                <option value={0}>Others</option>
              </select>
              <hr />
            </div>

            <div className="d-flex justify-content-around mb-2">
              {Loading ? (
                <span className="spinner-border text-primary"></span>
              ) : (
                <Button variant="primary" type="submit">
                  Save
                </Button>
              )}
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default EditProfile;
