import { createSlice } from "@reduxjs/toolkit";

export const InstSlice = createSlice({
  name: "inst",
  initialState: {
    insts: [],
    activeInst: {},
  },
  reducers: {
    fetchInsts: (state, action) => {
      state.insts = action.payload;
    },
    addInst: (state, action) => {
      state.insts = [action.payload, ...state.insts];
    },
    updateInst: (state, action) => {
      let index = state.insts.findIndex(
        (inst) => inst.linker == action.payload.linker
      );

      let prevInsts = [...state.insts];

      prevInsts.splice(
        index < 0 ? 1 : index,
        index < 0 ? 0 : 1,
        action.payload
      );

      state.insts = prevInsts;
    },
    deleteInst: (state, action) => {
      let newInsts = state.insts.filter(
        (inst) => inst.linker != action.payload.linker
      );
      state.insts = newInsts;
    },
    setActiveInst: (state, action) => {
      state.activeInst = action.payload;
    },
  },
});

export const { fetchInsts, addInst, updateInst, deleteInst, setActiveInst } =
  InstSlice.actions;

export default InstSlice.reducer;
