import { createSlice } from "@reduxjs/toolkit";

export const SubSlice = createSlice({
  name: "sub",
  initialState: {
    subs: [],
  },
  reducers: {
    fetchSubs: (state, action) => {
      state.subs = action.payload;
    },
    addSub: (state, action) => {
      state.subs = [action.payload, ...state.subs];
    },
    updateSub: (state, action) => {
      let index = state.subs.findIndex(
        (sub) => parseInt(sub.linker) === parseInt(action.payload.linker)
      );

      let prevSubs = [...state.subs];

      prevSubs.splice(index < 0 ? 1 : index, index < 0 ? 0 : 1, action.payload);

      state.subs = prevSubs;
    },
    deleteSub: (state, action) => {
      let newSubs = state.subs.filter(
        (sub) => parseInt(sub.linker) !== parseInt(action.payload.linker)
      );
      state.subs = newSubs;
    },
  },
});

export const { fetchSubs, addSub, updateSub, deleteSub } = SubSlice.actions;

export default SubSlice.reducer;
