import React from "react";
import { PencilSquare, Trash } from "react-bootstrap-icons";
import { useSelector } from "react-redux";

import GeneratePdf from "../../../../Utils/GeneratePdf";
const PaySlip = ({
  setShowModal,
  Entries,
  setEntry,
  month,
  staff,
  setType,
  Items,
}) => {
  const Cats = useSelector((state) => state.payrollCat.payrollCats).filter(
    (item) => parseInt(item.deleted) === 0
  );

  const GetCat = (catLinker) => {
    let cat = Cats.find((cat) => parseInt(catLinker) === parseInt(cat.linker));

    return cat || { name: catLinker };
  };
  const GetItem = (itemLinker) => {
    let item = Items.find(
      (cat) => parseInt(itemLinker) === parseInt(cat.linker)
    );

    return item || { name: "unknown" };
  };
  const ActiveCred = useSelector((state) => state.cred.activeCred);
  const inst = useSelector((state) => state.inst.activeInst);
  const IncomeCats = Cats.filter((cat) => cat.type === "Income");
  const IncomeItems = Items.filter((item) =>
    IncomeCats.some((cat) => parseInt(item.catLinker) === parseInt(cat.linker))
  );
  const IncomeEntries = Entries.filter((entry) =>
    IncomeItems.some(
      (item) => parseInt(entry.itemLinker) === parseInt(item.linker)
    )
  );
  const AllowanceCats = Cats.filter((cat) => cat.type === "Allowance");
  const AllowanceItems = Items.filter((item) =>
    AllowanceCats.some(
      (cat) => parseInt(item.catLinker) === parseInt(cat.linker)
    )
  );
  const AllowanceEntries = Entries.filter((entry) =>
    AllowanceItems.some(
      (item) => parseInt(entry.itemLinker) === parseInt(item.linker)
    )
  );
  const DeductionCats = Cats.filter((cat) => cat.type === "Deduction");
  const DeductionItems = Items.filter((item) =>
    DeductionCats.some(
      (cat) => parseInt(item.catLinker) === parseInt(cat.linker)
    )
  );
  const DeductionEntries = Entries.filter((entry) =>
    DeductionItems.some(
      (item) => parseInt(entry.itemLinker) === parseInt(item.linker)
    )
  );
  return (
    <div className="card m-2">
      <div
        id={`${staff.firstname}-${staff.lastname}-${staff.surname}-${month}-payslip`}
      >
        <div className="card-header">
          <p className="h6 text-capitalize">
            {inst.name} Payslip For {month}
          </p>
          <p className="h6 text-capitalize">Employee No: {staff.staffReg}</p>{" "}
          <p className="h6 text-capitalize">
            Employee Name: {staff.firstname} {staff.lastname} {staff.surname}
          </p>
        </div>
        <div className="card-body">
          <table className="table table-sm">
            {IncomeEntries.length > 0 ? (
              <>
                <tr>
                  <th>Incomes</th>
                  <td></td>
                </tr>
                <tr>
                  <td></td>

                  <td>
                    <table>
                      {IncomeEntries.map((entry) => (
                        <tr>
                          <th>
                            {GetCat(GetItem(entry.itemLinker).catLinker).name}
                          </th>
                          <td>
                            {entry.amount}{" "}
                            {parseInt(ActiveCred.admin) === 1 ? (
                              <>
                                <PencilSquare
                                  onClick={() => {
                                    setShowModal(true);
                                    setType("edit");
                                    setEntry({
                                      ...entry,
                                      catLinker: GetItem(entry.itemLinker)
                                        .catLinker,
                                    });
                                  }}
                                  style={{ cursor: "pointer" }}
                                  className="text-primary"
                                />
                                <Trash
                                  onClick={() => {
                                    setShowModal(true);
                                    setType("delete");
                                    setEntry({
                                      ...entry,
                                      catLinker: GetItem(entry.itemLinker)
                                        .catLinker,
                                    });
                                  }}
                                  style={{ cursor: "pointer" }}
                                  className="text-danger"
                                />
                              </>
                            ) : null}
                          </td>
                        </tr>
                      ))}
                    </table>
                  </td>
                </tr>
                <tr>
                  <th className="text-decoration-underline">Basic Salary</th>
                  <td></td>
                  <td></td>
                  <th className="text-decoration-underline">
                    {IncomeEntries.reduce((a, b) => +a + +b.amount, 0)}
                  </th>
                </tr>
              </>
            ) : null}
            {AllowanceEntries.length > 0 ? (
              <>
                <tr>
                  <th>Allowances</th>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <table>
                      {AllowanceEntries.map((entry) => (
                        <tr>
                          <th>
                            {GetCat(GetItem(entry.itemLinker).catLinker).name}
                          </th>
                          <td>
                            {entry.amount}{" "}
                            {parseInt(ActiveCred.admin) === 1 ? (
                              <>
                                <PencilSquare
                                  onClick={() => {
                                    setShowModal(true);
                                    setType("edit");
                                    setEntry({
                                      ...entry,
                                      catLinker: GetItem(entry.itemLinker)
                                        .catLinker,
                                    });
                                  }}
                                  style={{ cursor: "pointer" }}
                                  className="text-primary"
                                />
                                <Trash
                                  onClick={() => {
                                    setShowModal(true);
                                    setType("delete");
                                    setEntry({
                                      ...entry,
                                      catLinker: GetItem(entry.itemLinker)
                                        .catLinker,
                                    });
                                  }}
                                  style={{ cursor: "pointer" }}
                                  className="text-danger"
                                />
                              </>
                            ) : null}
                          </td>
                        </tr>
                      ))}
                    </table>
                  </td>
                </tr>
                <tr>
                  <th className="text-decoration-underline">Gross Salary</th>
                  <td></td>
                  <td></td>
                  <th className="text-decoration-underline">
                    {IncomeEntries.reduce((a, b) => +a + +b.amount, 0) +
                      AllowanceEntries.reduce((a, b) => +a + +b.amount, 0)}
                  </th>
                </tr>
              </>
            ) : null}
            {DeductionEntries.length > 0 ? (
              <>
                <tr>
                  <th>Deductions</th>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <table>
                      {DeductionEntries.map((entry) => (
                        <tr>
                          <th>
                            {GetCat(GetItem(entry.itemLinker).catLinker).name}
                          </th>
                          <td>
                            {entry.amount}{" "}
                            {parseInt(ActiveCred.admin) === 1 ? (
                              <>
                                <PencilSquare
                                  onClick={() => {
                                    setShowModal(true);
                                    setType("edit");
                                    setEntry({
                                      ...entry,
                                      catLinker: GetItem(entry.itemLinker)
                                        .catLinker,
                                    });
                                  }}
                                  style={{ cursor: "pointer" }}
                                  className="text-primary"
                                />
                                <Trash
                                  onClick={() => {
                                    setShowModal(true);
                                    setType("delete");
                                    setEntry({
                                      ...entry,
                                      catLinker: GetItem(entry.itemLinker)
                                        .catLinker,
                                    });
                                  }}
                                  style={{ cursor: "pointer" }}
                                  className="text-danger"
                                />
                              </>
                            ) : null}
                          </td>
                        </tr>
                      ))}
                    </table>
                  </td>
                </tr>
                <tr>
                  <th className="text-decoration-underline">Net Salary</th>
                  <td></td>
                  <td></td>
                  <th className="text-decoration-underline">
                    {IncomeEntries.reduce((a, b) => +a + +b.amount, 0) +
                      AllowanceEntries.reduce((a, b) => +a + +b.amount, 0) -
                      DeductionEntries.reduce((a, b) => +a + +b.amount, 0)}
                  </th>
                </tr>
              </>
            ) : null}
          </table>
        </div>{" "}
      </div>
      <div className="card-footer">
        <GeneratePdf
          id={`${staff.firstname}-${staff.lastname}-${staff.surname}-${month}-payslip`}
          name={`${staff.firstname}-${staff.lastname}-${staff.surname}-${month}-payslip`}
        ></GeneratePdf>
      </div>
    </div>
  );
};

export default PaySlip;
