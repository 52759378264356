import React from "react";
import { useParams } from "react-router-dom";

const NoExpenseCats = () => {
  const params = useParams();
  return (
    <tbody>
      <tr>
        <td>No Expense Categories</td>
      </tr>
    </tbody>
  );
};

export default NoExpenseCats;
