import swal from "sweetalert";
import { fetchActives, updateActive } from "../reducers/ActiveSlice";
import { ApiUrl } from "./Urls";

export const FetchActives = async (
	softwareId,
	token,
	dispatch,
	lastonline,
	from = "fetch",
	payload = {}
) => {
	if (from === "fetch") {
		FetchLiveActives(token, softwareId, lastonline, dispatch);
	} else if (parseInt(softwareId) === parseInt(payload.softwareId)) {
		dispatch(updateActive(payload));
	}
};

export const addLiveActive = (active, softwareId, token, dispatch) => {
	return fetch(`${ApiUrl}/activation/add`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...active,
			softwareId: softwareId,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 201) {
				dispatch(updateActive(res.active));
				swal({
					title: "Success, Activation will be reviewed within 6 hrs",
					text: "You can come here and check progress",
					icon: "success",
				});
			} else if (res.status === 403) {
				swal({
					title: "Spam noticed",
					text: "Retry after one month",
					icon: "error",
				});
			} else {
				swal({
					title: "Failed to connect",
					icon: "warning",
					timer: 3000,
				});
			}
		})
		.catch(() => {
			swal({
				title: "Failed to connect",
				icon: "warning",
				timer: 3000,
			});
		});
};

export const updateLiveActive = (active, token, dispatch) => {
	return fetch(`${ApiUrl}/activation/edit`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			...active,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(updateActive(res.active));
			} else {
				swal({
					title: "Failed to connect",
					icon: "warning",
					timer: 3000,
				});
			}
		})
		.catch(() => {
			swal({
				title: "Failed to connect",
				icon: "warning",
				timer: 3000,
			});
		});
};

export const FetchLiveActives = (token, softwareId, online, dispatch) => {
	fetch(`${ApiUrl}/activation/get`, {
		method: "POST",
		headers: {
			"content-type": "application/json",
			authorization: `bearer ${token}`,
		},
		body: JSON.stringify({
			online,
			softwareId,
		}),
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.status === 200) {
				dispatch(fetchActives(res.actives));
			}
		})
		.catch(() => {});
};
