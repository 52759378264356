import { createSlice } from "@reduxjs/toolkit";

export const ExpenseSlice = createSlice({
  name: "expense",
  initialState: {
    expenses: [],
  },
  reducers: {
    fetchExpenses: (state, action) => {
      state.expenses = action.payload;
    },
    addExpense: (state, action) => {
      state.expenses = [action.payload, ...state.expenses];
    },
    updateExpense: (state, action) => {
      let index = state.expenses.findIndex(
        (expense) =>
          parseInt(expense.linker) === parseInt(action.payload.linker)
      );

      let prevExpenses = [...state.expenses];

      prevExpenses.splice(
        index < 0 ? 1 : index,
        index < 0 ? 0 : 1,
        action.payload
      );

      state.expenses = prevExpenses;
    },
    deleteExpense: (state, action) => {
      let newExpenses = state.expenses.filter(
        (expense) =>
          parseInt(expense.linker) !== parseInt(action.payload.linker)
      );
      state.expenses = newExpenses;
    },
  },
});

export const { fetchExpenses, addExpense, updateExpense, deleteExpense } =
  ExpenseSlice.actions;

export default ExpenseSlice.reducer;
