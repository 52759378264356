import { createSlice } from "@reduxjs/toolkit";

export const EntrySlice = createSlice({
  name: "entry",
  initialState: {
    entries: [],
  },
  reducers: {
    fetchEntries: (state, action) => {
      state.entries = action.payload;
    },
    addEntry: (state, action) => {
      state.entries = [action.payload, ...state.entries];
    },
    updateEntry: (state, action) => {
      let index = state.entries.findIndex(
        (entry) => parseInt(entry.linker) === parseInt(action.payload.linker)
      );

      let prevEntries = [...state.entries];

      prevEntries.splice(
        index < 0 ? 1 : index,
        index < 0 ? 0 : 1,
        action.payload
      );

      state.entries = prevEntries;
    },
    deleteEntry: (state, action) => {
      let newEntries = state.entries.filter(
        (entry) => parseInt(entry.linker) !== parseInt(action.payload.linker)
      );
      state.entries = newEntries;
    },
  },
});

export const { fetchEntries, addEntry, updateEntry, deleteEntry } =
  EntrySlice.actions;

export default EntrySlice.reducer;
