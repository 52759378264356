import { createSlice } from "@reduxjs/toolkit";

export const ProductSlice = createSlice({
  name: "product",
  initialState: {
    products: [],
  },
  reducers: {
    fetchProducts: (state, action) => {
      state.products = action.payload;
    },
    addProduct: (state, action) => {
      state.products = [action.payload, ...state.products];
    },
    updateProduct: (state, action) => {
      let index = state.products.findIndex(
        (product) =>
          parseInt(product.linker) === parseInt(action.payload.linker)
      );

      let prevProducts = [...state.products];

      prevProducts.splice(
        index < 0 ? 1 : index,
        index < 0 ? 0 : 1,
        action.payload
      );

      state.products = prevProducts;
    },
    deleteProduct: (state, action) => {
      let newProducts = state.products.filter(
        (product) =>
          parseInt(product.linker) !== parseInt(action.payload.linker)
      );
      state.products = newProducts;
    },
  },
});

export const { fetchProducts, addProduct, updateProduct, deleteProduct } =
  ProductSlice.actions;

export default ProductSlice.reducer;
