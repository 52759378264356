import React, { useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { FilePdf } from "react-bootstrap-icons";

const GeneratePdf = ({ id, name }) => {
	const [loading, setLoading] = useState(false);

	const generatePdf = async () => {
		setLoading(true);
		var element = document.getElementById(id);
		await html2canvas(element).then(async (canvas) => {
			var pdf = new jsPDF("p", "pt", "letter");

			const pageWidth = 612; // 8.5 inches * 72 points per inch
			const pageHeight = 791; // 11 inches * 72 points per inch
			const canvasWidth = canvas.width;
			const canvasHeight = canvas.height;

			// Calculate the scaling factor to fit the width
			const scaleFactor = pageWidth / canvasWidth;

			for (let i = 0; i <= canvasHeight / (pageHeight / scaleFactor); i++) {
				let onePageCanvas = document.createElement("canvas");
				onePageCanvas.setAttribute("width", canvasWidth);
				onePageCanvas.setAttribute("height", pageHeight / scaleFactor);

				let ctx = onePageCanvas.getContext("2d");
				ctx.drawImage(
					canvas,
					0,
					i * (pageHeight / scaleFactor),
					canvasWidth,
					pageHeight / scaleFactor,
					0,
					0,
					canvasWidth,
					pageHeight / scaleFactor
				);

				let canvasDataUrl = onePageCanvas.toDataURL("image/png", 1.0);

				if (i > 0) {
					pdf.addPage("letter", "portrait");
				}

				pdf.setPage(i + 1);
				pdf.addImage(canvasDataUrl, "PNG", 0, 0, pageWidth, pageHeight);
			}

			pdf.save(`${name}-${Date.now()}.pdf`);
		});
		setLoading(false);
	};

	return (
		<div className="d-flex justify-content-center">
			{loading ? (
				<span className="spinner-border text-primary"></span>
			) : (
				<button
					onClick={generatePdf}
					className="btn btn-sm btn-outline-primary rounded"
				>
					<FilePdf /> Download Pdf
				</button>
			)}
		</div>
	);
};

export default GeneratePdf;
