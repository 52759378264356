import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";
import { FetchLiveActives, addLiveActive } from "../../Network/ActiveApi";
import { CountryCities } from "../../Utils/CountryCities";

const ManualPay2 = ({ softwareId }) => {
	//redux dispatch
	const dispatch = useDispatch();

	const [TransactionCode, setTransactionCode] = useState("");
	const [ConfirmCode, setConfirmCode] = useState("");
	const [Loading, setLoading] = useState(false);
	const Actives = useSelector((state) => state.active.actives);
	const [Country, setCountry] = useState("");

	useEffect(() => {
		FetchLiveActives("", softwareId, 0, dispatch);
		if (Intl) {
			let TzArr = Intl.DateTimeFormat().resolvedOptions().timeZone.split("/");
			let userCity = TzArr[TzArr.length - 1];
			let userCountry = CountryCities[userCity];
			setCountry(userCountry);
		}
	}, []);

	const UploadCode = async () => {
		setLoading(true);
		await addLiveActive(
			{ transaction: TransactionCode, country: Country },
			softwareId,
			"",
			dispatch
		);
		setLoading(false);
		setTransactionCode("");
		setConfirmCode("");
	};

	const SaveCode = (e) => {
		e.preventDefault();
		if (TransactionCode == "" || TransactionCode != ConfirmCode) {
			return;
		}
		swal({
			title: `Warning!, To avoid spammers we only allow activation code request per institution once in a month.`,
			text: `Please confirm the payment transaction code and then continue ${TransactionCode}`,
			icon: "warning",
			buttons: true,
		})
			.then((willReturn) => {
				if (willReturn) {
					UploadCode();
				}
			})
			.catch((err) => console.log(err));
	};
	return (
		<div className="card my-2">
			{Actives.length > 0 ? (
				<table className="table table-sm">
					<thead>
						<tr>
							<th>Transaction Code</th>
							<th>Status</th>
							<th>Activation Code</th>
						</tr>
					</thead>
					<tbody>
						{Actives.map((active) => (
							<tr key={active.linker}>
								<td>{active.transaction}</td>

								<td>
									{active.deleted != 0 ? (
										<span className="text-danger">Deleted</span>
									) : active.status == 0 ? (
										<span className="text-info">In Review</span>
									) : active.status == 2 ? (
										<span className="text-danger">Rejected</span>
									) : active.status == 1 ? (
										<span className="text-danger">Verified</span>
									) : (
										""
									)}
								</td>
								<td>
									{active.status == 0 ? "Check Later" : active.activation}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			) : null}
			<div className="card-header">
				<p className="h6 text-decoration-underline text-capitalize text-center">
					<strong>
						Pay Through Paypal Or Bank and enter the transaction code below.
					</strong>
				</p>
				<ul>
					<li>
						<strong> Paypal To : </strong>
						<em className="btn-link">contact.techsystems@gmail.com</em>{" "}
					</li>{" "}
					<li>
						<strong> Bank Transfer {"{Local & International}"} :</strong>
						<br />
						<span>
							<strong>Country :</strong> <em>Kenya</em>
						</span>{" "}
						<br />
						<span>
							<strong>City/Region :</strong> <em>Nairobi</em>
						</span>{" "}
						<br />
						<span>
							<strong>Bank :</strong> <em>Equity Bank</em>
						</span>{" "}
						<br />
						<span>
							<strong>Account Name :</strong> <em>Dazila Systems</em>
						</span>{" "}
						<br />
						<span>
							<strong>Account No :</strong> <em>0340185179998</em>
						</span>{" "}
						<br />
						<span>
							<strong>Purpose :</strong> <em>Rental Techsystem</em>
						</span>{" "}
					</li>
				</ul>
				<p className="h6 text-decoration-underline text-capitalize">
					<strong>In Case You need help :</strong>
				</p>
				<ul>
					<li>
						<strong>Email : </strong>
						<em>contact.techsystems@gmail.com</em>
					</li>
					<li>
						<strong>Whatsapp : </strong>
						<em>+254 703535116</em>
					</li>
					<li>
						<strong>Facebook : </strong>
						<em>www.facebook.com/techsystems.world</em>
					</li>
				</ul>
			</div>
			<form onSubmit={SaveCode}>
				<div className="card-body">
					<div className="form-group">
						<label>Transaction Code:</label>
						<input
							className="rounded form-control"
							placeholder="Enter Transaction Code"
							value={TransactionCode}
							onChange={(e) => setTransactionCode(e.target.value)}
							required
						/>
					</div>
					<hr />
					<div className="form-group">
						<label>
							Confirm Transaction Code:{" "}
							<small className="text-danger">
								{TransactionCode != "" && TransactionCode != ConfirmCode
									? "Not Matching"
									: ""}
							</small>
						</label>
						<input
							className="rounded form-control"
							placeholder="Confirm Transaction Code"
							value={ConfirmCode}
							onChange={(e) => setConfirmCode(e.target.value)}
							required
						/>
					</div>
				</div>
				<div className="card-footer">
					<div className="d-flex justify-content-around">
						{Loading ? (
							<span className="spinner-border text-primary"></span>
						) : (
							<button
								type={"submit"}
								className="btn btn-outline-primary rounded"
							>
								Submit
							</button>
						)}
					</div>
				</div>
			</form>
		</div>
	);
};

export default ManualPay2;
