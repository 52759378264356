import React from "react";
import { useParams } from "react-router-dom";

const NoCats = () => {
  const params = useParams();
  return (
    <tbody>
      <tr>
        <td>No Payroll Items</td>

        <td>
          This are various payroll items , which include various types of
          incomes, allowances and deductions
        </td>
      </tr>
    </tbody>
  );
};

export default NoCats;
