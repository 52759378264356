import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { PlainList } from "flatlist-react";
import PaySlip from "./PaySlip";
import PaySlipEntryCrud from "./PaySlipEntryCrud";

const PaySlips = () => {
  const params = useParams();

  const [ShowModal, setShowModal] = useState(false);
  const [Entry, setEntry] = useState({});
  const [Type, setType] = useState("add");

  const Items = useSelector((state) => state.payrollItem.payrollItems).filter(
    (item) =>
      parseInt(item.deleted) === 0 &&
      parseInt(item.staffLinker) === parseInt(params.staffLinker)
  );

  const Entries = useSelector(
    (state) => state.payrollEntry.payrollEntries
  ).filter(
    (item) =>
      parseInt(item.deleted) === 0 &&
      parseInt(item.staffLinker) === parseInt(params.staffLinker)
  );
  const Staff = useSelector((state) => state.cred.creds).find(
    (staff) => parseInt(staff.linker) === parseInt(params.staffLinker)
  );

  const Months = [
    ...new Set(
      Entries.sort((a, b) => b.linker - a.linker).map((entry) => entry.month)
    ),
  ];

  return (
    <>
      <div className="row justify-content-center">
        <PlainList
          list={Months}
          renderOnScroll
          renderItem={(month) => (
            <div key={month} className="col-md-12 col-lg-6 col-xl-4">
              <PaySlip
                setShowModal={setShowModal}
                Entries={Entries.filter((entry) => entry.month === month)}
                setEntry={setEntry}
                month={month}
                staff={Staff || {}}
                setType={setType}
                Items={Items}
              ></PaySlip>
            </div>
          )}
        />
      </div>
      <PaySlipEntryCrud
        entry={Entry}
        setEntry={setEntry}
        type={Type}
        ShowModal={ShowModal}
        setShowModal={setShowModal}
      ></PaySlipEntryCrud>
    </>
  );
};

export default PaySlips;
