import { createSlice } from "@reduxjs/toolkit";

export const OnlinePaySlice = createSlice({
  name: "onlinePay",
  initialState: {
    onlinePays: [],
  },
  reducers: {
    fetchOnlinePays: (state, action) => {
      state.onlinePays = action.payload;
    },
    updateOnlinePay: (state, action) => {
      let index = state.onlinePays.findIndex(
        (onlinePay) =>
          parseInt(onlinePay.linker) === parseInt(action.payload.linker)
      );

      let prevOnlinePays = [...state.onlinePays];

      prevOnlinePays.splice(
        index < 0 ? 1 : index,
        index < 0 ? 0 : 1,
        action.payload
      );

      state.onlinePays = prevOnlinePays;
    },
  },
});

export const {
  fetchOnlinePays,
  addOnlinePay,
  updateOnlinePay,
  deleteOnlinePay,
} = OnlinePaySlice.actions;

export default OnlinePaySlice.reducer;
