import React from "react";
import image from "../../assets/images/health-management-tech-system-logo.jpg";

import AuthModal from "../Auth/AuthModal";
import Header from "../../components/Header";

const Login = () => {
  return (
    <div
      style={{
        minHeight: "100vh",
        minWidth: "100vw",

        backgroundImage: `url(${image})`,
        backgroundSize: "cover",
        backgroundRepeat: "repeat-y",
        backgroundPosition: "center",
      }}
    >
      <Header></Header>
      <div
        style={{
          marginTop: "40px",
          backgroundColor: "rgba(64, 99, 112, 0.5)",
          minHeight: "100vh",
          minWidth: "100vw",
          paddingTop: "40vh",
        }}
      >
        <div
          style={{
            backgroundColor: "rgba(255, 255, 255)",
            borderRadius: "20%",
            padding: "2vh",
            minHeight: "10vh",
          }}
        >
          <AuthModal></AuthModal>
        </div>
      </div>
    </div>
  );
};

export default Login;
