import swal from "sweetalert";
import {
  fetchPayrollEntries,
  updatePayrollEntry,
} from "../reducers/PayrollEntrySlice";
import { ApiUrl } from "./Urls";
import { fetchStaffPayEntries } from "../reducers/StaffPayEntrySlice";

export const FetchPayrollEntries = async (
  token,
  instLinker,
  dispatch,
  online,
  from = "fetch",
  payload = {}
) => {
  if (from === "fetch") {
    return await FetchLivePayrollEntries(token, instLinker, online, dispatch);
  } else if (parseInt(instLinker) === parseInt(payload.instLinker)) {
    dispatch(updatePayrollEntry(payload));
  }
};

export const addLivePayrollEntry = (
  payrollEntry,
  instLinker,
  token,
  dispatch,
  setLoading,
  InputItems,
  index,
  setShowModal
) => {
  return fetch(`${ApiUrl}/payroll-entries/add`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    },
    body: JSON.stringify({
      ...payrollEntry,
      instLinker,
    }),
  })
    .then((res) => res.json())
    .then(async (res) => {
      if (res.status === 201) {
        dispatch(updatePayrollEntry(res.payrollEntry));
      }

      if (index === InputItems.length - 1) {
        await FetchLivePayrollEntries(token, instLinker, 0, dispatch);
        setLoading(false);
        setShowModal(false);
        swal({
          title: "PaySlips Generated Successfully",
          icon: "success",
          timer: 3000,
        });
      }
    })
    .catch(() => {
      swal({
        title: "Failed to connect",
        icon: "warning",
        timer: 3000,
      });
    });
};

export const updateLivePayrollEntry = (payrollEntry, token, dispatch) => {
  return fetch(`${ApiUrl}/payroll-entries/edit`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    },
    body: JSON.stringify({
      ...payrollEntry,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 200) {
        dispatch(updatePayrollEntry(res.payrollEntry));
      }
    })
    .catch(() => {
      swal({
        title: "Failed to connect",
        icon: "warning",
        timer: 3000,
      });
    });
};

export const FetchLivePayrollEntries = async (
  token,
  instLinker,
  online,
  dispatch
) => {
  return await fetch(`${ApiUrl}/payroll-entries/get`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    },
    body: JSON.stringify({
      online,
      instLinker,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 200) {
        dispatch(fetchPayrollEntries(res.payrollEntries));
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const StaffLivePayrollEntries = (
  token,
  instLinker,
  online,
  dispatch
) => {
  return fetch(`${ApiUrl}/payroll-entries/staff`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `bearer ${token}`,
    },
    body: JSON.stringify({
      online,
      instLinker,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.status === 200) {
        dispatch(fetchStaffPayEntries(res.payrollEntries));
      }
    })
    .catch(() => {});
};
