import React from "react";
import { useParams } from "react-router-dom";

const NoMode = () => {
  const params = useParams();
  return (
    <tbody>
      <tr>
        <td>No Payment Modes</td>

        <td>
          Payment modes are various modes of payments that students use to pay
          to your institution to help in finance collection eg bank accounts,
          online wallets,
        </td>
      </tr>
    </tbody>
  );
};

export default NoMode;
