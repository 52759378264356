import { createSlice } from "@reduxjs/toolkit";

export const PayrollEntrySlice = createSlice({
  name: "payrollEntry",
  initialState: {
    payrollEntries: [],
  },
  reducers: {
    fetchPayrollEntries: (state, action) => {
      state.payrollEntries = action.payload;
    },
    addPayrollEntry: (state, action) => {
      state.payrollEntries = [action.payload, ...state.payrollEntries];
    },
    updatePayrollEntry: (state, action) => {
      let index = state.payrollEntries.findIndex(
        (payrollEntry) =>
          parseInt(payrollEntry.linker) === parseInt(action.payload.linker)
      );

      let prevPayrollEntries = [...state.payrollEntries];

      prevPayrollEntries.splice(
        index < 0 ? 1 : index,
        index < 0 ? 0 : 1,
        action.payload
      );

      state.payrollEntries = prevPayrollEntries;
    },
    deletePayrollEntry: (state, action) => {
      let newPayrollEntries = state.payrollEntries.filter(
        (payrollEntry) =>
          parseInt(payrollEntry.linker) !== parseInt(action.payload.linker)
      );
      state.payrollEntries = newPayrollEntries;
    },
  },
});

export const {
  fetchPayrollEntries,
  addPayrollEntry,
  updatePayrollEntry,
  deletePayrollEntry,
} = PayrollEntrySlice.actions;

export default PayrollEntrySlice.reducer;
